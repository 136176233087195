/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* fonts start*/

// could use light as normal and medium as bold if it is easier on the eyes

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    src: url('./assets/fonts/Roboto-Bold.ttf');
}

* {
  --ion-font-family:  'Roboto' !important;
  font-family:  'Roboto' !important;
}

/* fonts end */

.animate-all {
    transition: all 0.5s ease;
}

.import-loading {
    opacity: 0.95;
}

.add-modal {
    --width: 1100px;
    --height: 300px
}

.ai-modal {
    --width: 1100px;
    --height: 800px;
}

.warning {
    color: red !important;
}

.positive {
    color: green !important;
}

.hidden {
    visibility: hidden;
}

.full_height {
    height: 100%;
}

.full_width {
    width: 100%;
}

.full_size {
    height: 100%;
    width: 100%;
}

.center_children_h {
    display: flex;
    justify-content: center;
}

.center_children_v {
    display: flex;
    align-content: center;
    align-items: center;
}

// hide up and down arrows for number inputs 
.native-input.sc-ion-input-md {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
}

@media print {
    body * {
        visibility: hidden;
    }

    .print_container, .print_container * {
        visibility: visible;
    }

    .print_container {
        position: absolute;
        left: 0;
        top: 0;
    }

    .print_no_break {
        break-inside: avoid;
    }

    .print_hidden {
        display: none !important;
    }

    // this enables printing more than the first page in ionic
    .ion-page {
        display: initial !important;
        position: initial !important;
      }
      body {
        position: initial !important;
        max-height: initial !important;
        overflow: auto !important;
      }
      ion-router-outlet {
        overflow: auto !important;
        contain: none !important;
        position: initial !important;
      }
      ion-content {
        --offset-bottom: unset !important;
        display: unset !important;;
        position: unset !important;;
      }
}
